import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {useLocation} from "react-router";

interface UserProfile {
    walletActivated: boolean;
    canCalculatePoints: boolean;
    currentScore: number;
    currentRanking: number;
    lastWeekScore: number;
    username: string;
    imageURL:string;
    id: string;
    nextResetTime: string;
    cooldown: string;
}

interface UserProviderProps {
    children: ReactNode;
}

interface UserContextType {
    profile: UserProfile | undefined;
    updateProfile: (updatedProfile: Partial<UserProfile>) => void;
    fetchProfile: () => Promise<void>;
    isPopupOpen: boolean;
    setPopupOpen: (open: boolean) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserProfile = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserProfile must be used within a UserProvider');
    }
    return context;
};

export const UserProvider: React.FC<UserProviderProps> = ({ children}) => {
    const [profile, setProfile] = useState<UserProfile | undefined>(undefined);

    const [isPopupOpen, setPopupOpen] = useState(false);

    const fetchProfile = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/profile`, { withCredentials: true });
            setProfile(response.data);
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    };

    useEffect(() => {
        fetchProfile();
    }, []);

    const updateProfile = (updatedProfile: Partial<UserProfile>) => {
        setProfile((prevProfile) => {
            if (!prevProfile) return undefined; // Ensure prevProfile is defined
            return {
                ...prevProfile,
                ...updatedProfile,
            };
        });
    };

    return (
        <UserContext.Provider value={{ profile, updateProfile, fetchProfile, isPopupOpen, setPopupOpen }}>
            {children}
        </UserContext.Provider>
    );
};
