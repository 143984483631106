import {FC} from 'react';
import {Helmet} from 'react-helmet';
import Navbar from "../components/Navbar";
import MainContent from "../components/MainContent";

const Main: FC = () => {
    return (
        <div>
            <Helmet>
                <title>Post2Earn</title>
                <link rel="canonical" href="https://post2earn.co.uk" />
            </Helmet>
            <Navbar/>
            <MainContent/>
        </div>
    );
};

export default Main;
