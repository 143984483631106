/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

export const hrStyle = css`
    margin-top: 64px;
    flex-shrink: 0;
    width: 100%;
    border-width: 0 0 thin;
    border-style: solid;
    border-color: black;
    align-self: stretch;
    height: auto;
    @media (max-width: 600px) {
        margin-top: 32px;
    }
`;

export const mainContentStyles = css`
    display: flex;
    overflow: hidden auto;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    text-align: center;

    margin-top: 64px;

    @media (min-width: 768px) {
        margin-top: 96px;
    }
`;

export const blockTitleStyles = css`
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 0 20px;
    text-align: center;

    @media (min-width: 768px) {
        margin: 0 0 40px;
    }
    @media (min-width: 1024px) {
        font-size: 1.875rem;
    }
    @media (min-width: 1280px) {
        font-size: 2.25rem;
    }
`;

export const panelWrapperStyles = css`text-align: start;
    background-color: white;
    border: 1px solid black;
    padding: 28px 20px;
    border-left-width: 0;
    border-right-width: 0;
    margin-top: -1px;

    @media (min-width: 768px) {
        padding: 40px 32px;
        border-left-width: 1px;
        border-right-width: 1px;
    }
`;

export const textXS = css`
    font-size: 0.75rem; /* 12px */
    line-height: 1rem; /* 16px */
`

export const textSM = css`
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
`;

export const textBase = css`
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
`;

export const textLG = css`
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
`;

export const textXL = css`
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
`;

export const text2XL = css`
    font-size: 1.5rem; /* 24px */
    line-height: 2rem; /* 32px */
`;

export const text3XL = css`
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
`;

export const text4XL = css`
    font-size: 2.25rem; /* 36px */
    line-height: 2.5rem; /* 40px */
`;

export const text5XL = css`
    font-size: 3rem; /* 48px */
    line-height: 1;
`;

export const text6XL = css`
    font-size: 3.75rem; /* 60px */
    line-height: 1;`;

export const containerStyles = css`
    max-width: 1536px;
    margin: 0 auto;
    padding: 0 12px;
    width: 100%;

    @media (min-width: 768px) {
        padding: 0 20px;
    }

    @media (min-width: 1024px) {
        padding: 0 32px;
    }
`;

export const containerMobFullStyles = css`
    @media (max-width: 767px) {
        padding: 0;
    }
`;

export const pageTitleStyles = css`
    font-weight: 700;
    margin: 0;

    ${text3XL};

    @media (min-width: 768px) {
        ${text4XL};
    }

    @media (min-width: 1024px) {
        ${text5XL};
    }

    @media (min-width: 1280px) {
        ${text6XL};
    }
`;