export type EventType = 1 | 2 | 3;
export type InteractionType = 'quote' | 'retweet' | 'reply' | 'post';

interface ViralityScore {
    likes: number;
    retweets: number;
    bookmarks: number;
    impressions: number;
    quotes: number;
    replies: number;
}

export interface Interaction {
    eventType: EventType;
    interactionType: InteractionType;
    virality: ViralityScore;
    followers: number;
}

const Pbase = 1;
const Mevent = {
    1: 4,
    2: 1,
    3: 1
};
const Minteraction = {
    quote: 2,
    retweet: 1,
    reply: 1,
    post: 3
};
const k = 0;

export const calculateViralityScore = (virality: ViralityScore): number => {
    const { likes, retweets, bookmarks,impressions,replies,quotes } = virality;
    return 100 * likes + 300 * retweets + 150 * bookmarks + 200 * replies + 250 * quotes + impressions;
};

const calculateFollowerAdjustmentFactor = (followers: number): number => {
    return 1 + (k / (followers + 1));
};

export const calculatePoints = (interaction: Interaction): number => {
    const { eventType, interactionType, virality, followers } = interaction;


    // Calculate V (Virality Score)
    let V: number;
    V = calculateViralityScore(virality);


    // Calculate F (Follower Adjustment Factor)
    const F = calculateFollowerAdjustmentFactor(followers);

    // Get Mevent and Minteraction
    const MeventValue = Mevent[eventType];
    const MinteractionValue = Minteraction[interactionType];

    // Calculate Ptotal
    let Ptotal;

    if(interactionType=='retweet') {
        Ptotal =  MeventValue * 1000;
    } else {
        if(eventType == 1){
            Ptotal = Pbase * MeventValue * V * F
        } else {
            Ptotal = Pbase * MeventValue * MinteractionValue * V * F;
        }
    }

    return Ptotal;
};
