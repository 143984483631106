/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import {FC} from "react";

const GlobalStyles:FC = () => (
    <Global
        styles={css`
            //::-webkit-scrollbar {
            //  width: 8px;
            //  height: 8px;
            //}
            //::-webkit-scrollbar-corner {
            //  opacity: 0;
            //}
            //::-webkit-scrollbar-track {
            //  opacity: 0;
            //}
            //::-webkit-scrollbar-thumb {
            //  border-radius: 5px;
            //  border: 2px solid transparent;
            //  background: rgba(0, 0, 0, 1);
            //}

            html {
                -webkit-font-smoothing: antialiased;
                box-sizing: border-box;
                text-size-adjust: 100%;
            }

            *,
            *::before,
            *::after {
                box-sizing: inherit;
            }

            body {
                margin: 0;
                color: rgb(0, 0, 0);
                font-family: Montserrat;
                font-weight: 700;
                font-size: 1rem;
                line-height: 1.5;
                background-color: rgb(255, 255, 255);
                display: block;
            }
        `}
    />
);

export default GlobalStyles;
