/** @jsxImportSource @emotion/react */
import React from 'react';
import {Link} from "react-router-dom";
import {css} from '@emotion/react';

const Logo = ({fill = 'white'}) => {
    return (
        <Link to={'/'} css={
            css`display: flex;
                flex-direction: row;
                text-decoration: none;
            `}
        >
            <svg width="67" height="16" viewBox="0 0 67 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M61.5876 3.20392C58.5962 3.20392 56.1751 5.41825 56.1751 8.15187C56.1751 10.8855 58.5962 13.0998 61.5876 13.0998C64.5789 13.0998 67 10.8855 67 8.15187C67 5.41825 64.5789 3.20392 61.5876 3.20392ZM61.5876 10.2878C59.7067 10.2878 58.1861 9.34721 58.1861 8.19106C58.1861 7.03491 59.7067 6.09431 61.5876 6.09431C63.4684 6.09431 64.9891 7.03491 64.9891 8.19106C64.9891 9.34721 63.4684 10.2878 61.5876 10.2878ZM10.9849 3.05695H8.0436V4.42866C7.19321 3.68401 6.09272 3.21372 4.87218 3.21372C2.18097 3.21372 0 5.42805 0 8.16167C0 10.8953 2.18097 13.1096 4.87218 13.1096C6.09272 13.1096 7.18321 12.6393 8.0436 11.8947V13.188H12.9258V10.2878H10.9849V3.05695ZM8.05361 9.60196C8.05361 9.60196 7.03315 10.229 5.75258 10.2878C3.62162 10.376 2.10093 9.38641 2.10093 8.23025C2.10093 7.0741 3.46155 6.1139 5.75258 6.09431C7.07317 6.08451 8.05361 6.85854 8.05361 6.85854V9.60196ZM26.732 10.278H43.8896V7.37783H26.732V10.278ZM20.9694 4.41886C20.119 3.67422 19.0185 3.20392 17.798 3.20392C15.1068 3.20392 12.9258 5.41825 12.9258 8.15187C12.9258 10.8855 15.1068 13.0998 17.798 13.0998C19.0185 13.0998 20.109 12.6295 20.9694 11.8849V13.1782H23.9107V0H20.9694V4.41886ZM20.9694 9.60196C20.9694 9.60196 19.9489 10.229 18.6684 10.2878C16.5374 10.376 15.0167 9.38641 15.0167 8.23025C15.0167 7.0741 16.3773 6.1139 18.6684 6.09431C19.989 6.08451 20.9694 6.85854 20.9694 6.85854V9.60196ZM53.9742 3.23331L51.453 9.3766L48.8219 3.20392H45.6504L48.6718 10.2094L51.1129 10.2192L49.9324 13.0998H46.5208V16H51.393V15.9902H51.9333L57.1156 3.20392L53.9742 3.23331Z"
                    fill={fill}
                />
            </svg>
        </Link>
    );
};

export default Logo;