import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App'; // Import your App component
import './assets/fonts/fonts.css';



if (process.env.NODE_ENV === 'prod') {
    console.log = () => {};
    console.error = () => {};
    console.warn = () => {};
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

reportWebVitals();

