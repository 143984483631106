/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { css } from '@emotion/react';
import axios from 'axios';
import { useUserProfile } from "../contexts/UserContext";

const mockProfile = {
    walletActivated: true, // Change this to false to simulate not connected
    canCalculatePoints: true, // Change this to false to simulate cooldown
    currentScore: 1000,
    currentRanking: 5,
    lastWeekScore: 200,
    id: 'user123',
    imageUrl: 'https://pbs.twimg.com/profile_images/1462125055064821769/PBeanQK9_normal.jpg',
    nextResetTime: '2024-08-12T00:00:00Z'
};

const ProfileName: React.FC = () => {
    const { profile, updateProfile } = useUserProfile();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/logout`, {}, { withCredentials: true });
            updateProfile({
                walletActivated: false,
                canCalculatePoints: false,
                currentScore: 0,
                currentRanking: 0,
                lastWeekScore: 0,
                imageURL:'',
                id: '',
                nextResetTime: '',
            }); // Clear the profile from context
            window.location.reload(); // Reload the page
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const open = !!anchorEl;

    if (!profile) return null;

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                cursor: pointer;
            `}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={(e) => {
                const relatedTarget = e.relatedTarget as Node | null;

                // Check if relatedTarget is null, a window, or undefined
                if (relatedTarget instanceof Node && anchorEl?.contains(relatedTarget)) {
                    // Do nothing if relatedTarget is within the popover
                    return;
                }

                // Close the popover if relatedTarget is outside of the popover or is null
                handlePopoverClose();
            }}

        >
            {profile.imageURL && (
                <img
                    src={profile.imageURL}
                    alt="Profile"
                    style={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        marginRight: '10px',
                    }}
                />
            )}
            <div
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                css={css`color: white;margin-right: 10px;@media(max-width: 450px){display: none;} `}
            >
                @{profile.username}
            </div>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'auto',
                    zIndex: 1500,
                    '& .MuiPopover-paper': {
                        backgroundColor: 'white',
                        color: 'black',
                    },
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <div
                    css={css`
                        color: black;
                        padding: 10px;
                        &:hover {
                            background-color: lightgray;
                        }
                    `}
                    onClick={handleLogout}
                >
                    Log out
                </div>
            </Popover>
        </div>
    );
};

export default ProfileName;
