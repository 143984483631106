/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {FC} from 'react';


export const TwitterSpan: FC<{ word: string }> = (word) => {
    return (
        <span css={css`
            max-width: 100%;
            font-size: 0.8125rem;
            display: inline-flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            height: 32px;
            border-radius: 16px;
            white-space: nowrap;
            outline: 0;
            text-decoration: none;
            padding: 0;
            vertical-align: middle;
            box-sizing: border-box;
            background-color: transparent;
            border: 1px solid rgb(235, 153, 100);
            color: rgb(227, 46, 0);
            font-weight: 600;
        `}>
            <span css={css`overflow: hidden;
                text-overflow: ellipsis;
                padding-left: 12px;
                padding-right: 12px;
                white-space: nowrap;`
            }>
                {word.word}
            </span>
        </span>
    );
}