/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {useEffect, useRef, useState} from "react";
import {ThemeProvider} from '@mui/material/styles';
import {Slider, SvgIcon} from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplyIcon from '@mui/icons-material/Reply';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import {blockTitleStyles, hrStyle, textSM} from "../styles/OtherStyles";
import {TotalMetrics} from "./TotalMetrics";
import {
    calculatePoints,
    Interaction,
    InteractionType
} from "../helpers/calculatePoints";


enum Types1 {'Original Tweet' = 2, "AdYo's post" = 1, "Other's post" = 3};

enum Types2 {
    'Retweets' = 'retweet',
    'Replies' = 'reply',
    'Bookmarks' = 'bookmark',
    'post' = 'post',
    'Quotes' = 'quote'
};

const formatNumber = (num: number) => {
    return new Intl.NumberFormat('en-US').format(num);
};

export const InteractiveCalculator = () => {
    const [sliderKey, setSliderKey] = useState(0);
    const [choice, setChoice] = useState<keyof typeof Types1>('Original Tweet');
    const [chosen, setChosen] = useState(false);

    const [choice2, setChoice2] = useState<keyof typeof Types2>('post');
    const [chosen2, setChosen2] = useState(false);

    const triggerRef = useRef<HTMLDivElement | null>(null);
    const triggerRef2 = useRef<HTMLDivElement | null>(null);

    const [likes, setLikes] = useState<number>(0);
    const [retweets, setRetweets] = useState<number>(0);
    const [bookmarks, setBookmarks] = useState<number>(0);
    const [quotes, setQuotes] = useState<number>(0);
    const [replies, setReplies] = useState<number>(0);
    const [impressions, setImpressions] = useState<number>(0);
    const [numberOfRetweets, setNumberOfRetweets] = useState<number>(0);
    const [multiplier, setMultiplier] = useState<number>(1);

    const [totalPoints, setTotalPoints] = useState<number>(0);

    const dropDownStyle = css`
        padding: 0 12px;
        text-align: left;
        user-select: none;

        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }`;

    const selectStyles = css`
        ${textSM};
        margin-top: 2px;
        line-height: 1.4375em;
        color: rgba(0, 0, 0);
        box-sizing: border-box;
        cursor: text;
        display: inline-flex;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        border: 1px solid ${chosen ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.4)'};
        transition: 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        padding: 10px 5px;
        user-select: none;

        @media (min-width: 345px) {
            font-size: 1rem;
            margin-top: 0;
        }
        
        @media (max-width: 1199.95px) {
            margin-right: 0;
        }

        &:hover {
            box-shadow: ${chosen ? '0 0 1px 1px rgba(0,0,0,1);'
                    : '0 0 1px 1px rgba(0,0,0,0.7);'
            }
        }
    `

    const cssStyleBox1Choice2 = css`${selectStyles}&:hover {
        box-shadow: ${chosen2 ? '0 0 1px 1px rgba(0,0,0,1);'
                : '0 0 1px 1px rgba(0,0,0,0.7);'}`;

    const cssStyleBox2 = css`
        height: auto;
        min-height: 1.4375em;
        text-overflow: ellipsis;
        white-space: nowrap;
        //padding-right: 32px;
        align-self: start;
        padding-left: 4px;

        @media (min-width: 768px) {
            padding-left: 20px;
        }
    `;

    const svgStyle = css`
        align-self: end;
        width: 1em;
        height: 1em;
        display: inline-block;
        fill: currentcolor;
        flex-shrink: 0;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-size: 1.5rem;
        color: rgb(0, 0, 0);
        transform: ${chosen ? 'rotate(180deg)' : ''};`


    const svgStyle2 = css`${svgStyle}transform: ${chosen2 ? 'rotate(180deg)' : ''};`

    const dropDownList = css`
        width: 100%;
        padding: 0 0;
        position: absolute;
        z-index: 1000;
        list-style: none;
        line-height: 1.5;
        transform: translate(0px, 50px);
        border: 1px solid rgba(255, 255, 255, 0.12);
        background-color: rgb(18, 18, 18);
        color: white;
        font-size: 1rem;
        border-radius: 12px;
    `

    const dropDownList2 = css`
        ${dropDownList};
        transform: translate(0px, 150px);
    `;

    const rangeTitleStyle = css`
        font-size: 1rem; /* 16px */
        line-height: 1.5rem; /* 24px */
        font-weight: 600;
        margin-left: 1rem;
    `;

    const rangeValueStartStyle = css`
        font-size: 1rem; /* 16px */
        line-height: 1.5rem; /* 24px */
        font-weight: 500;
        margin-left: 7px;
        margin-right: 16px;
    `;

    const rangeValueStyle = css`
        font-size: 1rem; /* 16px */
        line-height: 1.5rem; /* 24px */
        font-weight: 500;
        margin-left: 12px;
    `;

    const totalPointsStyles = css`
        align-self: start;
        display: inline-flex;
        padding: 10px 0 0 0 ;
        font-size: 1rem;
        column-gap: 8px;
        flex-wrap: wrap;
        
        @media (min-width: 768px) {
            padding: 10px 0 0 20px;
        }

    `;

    const totalPointsValueStyles = css`
        display: inline-flex;
        align-self: center;
        color: rgb(0, 0, 0);
        background-color: rgb(221, 221, 221);
        white-space: nowrap;
        align-items: center;
        padding-left: 12px;
        padding-right: 12px;
        height: 32px;
    `;


    const handleDocumentClick = (event: MouseEvent) => {
        if (triggerRef.current && !triggerRef.current.contains(event.target as Node)) {
            setChosen(false);
        }
    };

    const handleDocumentClick2 = (event: MouseEvent) => {
        if (triggerRef2.current && !triggerRef2.current.contains(event.target as Node)) {
            setChosen2(false);
        }
    }


    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        document.addEventListener('click', handleDocumentClick2);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
            document.removeEventListener('click', handleDocumentClick2);
        };
    }, []);

    const nullifyStates = () => {
        setQuotes(0)
        setReplies(0)
        setRetweets(0)
        setLikes(0)
        setImpressions(0)
        setNumberOfRetweets(0)
        setBookmarks(0)
        setTotalPoints(0)
        setMultiplier(1)

        // Change the slider key to force re-render
        setSliderKey(prevKey => prevKey + 1);
    }

    useEffect(() => {
        let multiplierValue = 1 // Default multiplier

        // Determine multiplier based on tweet type and interaction type
        if (choice === "AdYo's post") {
            multiplierValue = 4
            if (choice2 === 'Retweets') {
                setTotalPoints(numberOfRetweets * 4000);
                setMultiplier(multiplierValue);
                return;
            }
        } else if (choice === 'Original Tweet') {
            multiplierValue = 3
        } else if (choice === "Other's post") {
            if (choice2 === 'Replies') {
                multiplierValue = 1
            } else if (choice2 === 'Quotes') {
                multiplierValue = 2
            } else if (choice2 === 'Retweets') {
                multiplierValue = 1
                setTotalPoints(numberOfRetweets * 1000);
                setMultiplier(multiplierValue);
                return;// Set multiplier to +1000p for retweets
            }
        }

        // Calculate interaction points
        const interaction: Interaction = {
            eventType: Types1[choice],
            interactionType: Types2[choice2] as InteractionType,
            followers: 20,
            virality: {
                likes,
                retweets,
                bookmarks,
                impressions,
                replies,
                quotes
            },
        };

        const points = calculatePoints(interaction);
        setTotalPoints(Math.ceil(points));
        setMultiplier(multiplierValue); // Update multiplier state

    }, [choice, choice2, likes, retweets, bookmarks, impressions, numberOfRetweets, replies, quotes]);


    return (
        <div css={css`
            transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        `}>
            <div css={css`display: flex;
                flex-direction: column;`}>
                <h5 css={blockTitleStyles}>
                    Expected Point Calculator
                </h5>
                <div css={css`display: flex;
                    flex-direction: row;`}>
                    <div css={css`display: flex;
                        flex-direction: column;
                        width: 50%`}>
                        <div css={css`
                            position: relative;
                            display: inline-flex;
                            flex-direction: column;
                            vertical-align: top`}>
                            <div css={css`
                                font-size: 0.75rem;
                                line-height: 1rem;
                                text-align: left;
                                margin-bottom: 8px;
                            `}>
                                Tweet Type
                            </div>
                            <div ref={triggerRef}
                                 onClick={() => setChosen(!chosen)} css={selectStyles}>
                                <div css={cssStyleBox2}>
                                    {choice}
                                </div>
                                <svg viewBox="0 0 24 24" css={svgStyle}>
                                    <path d="M7 10l5 5 5-5z"></path>
                                </svg>
                            </div>
                            {chosen && (
                                <ul css={dropDownList}>
                                    <li onClick={() => {
                                        setChoice('Original Tweet');
                                        setChoice2('post');
                                        nullifyStates();
                                    }} css={css`${dropDownStyle};
                                        border-radius: 12px 12px 0px 0px;
                                        padding-top: 5px`}>Original Tweet
                                    </li>
                                    <li onClick={() => {
                                        setChoice("AdYo's post");
                                        setChoice2('Retweets');
                                        nullifyStates();
                                    }} css={dropDownStyle}>AdYo post
                                    </li>
                                    <li onClick={() => {
                                        setChoice("Other's post");
                                        setChoice2('Retweets');
                                        nullifyStates();
                                    }} css={css`${dropDownStyle};
                                        border-radius: 0px 0px 12px 12px;
                                        padding-bottom: 5px`}>Other's post
                                    </li>
                                </ul>
                            )}
                            {choice != 'Original Tweet' && (
                                <>
                                    <div css={css`font-size: 0.75rem;
                                        text-align: left;
                                        margin-top: 32px;`}>
                                        Interaction type
                                    </div>
                                    <div ref={triggerRef2} onClick={() => {
                                        setChosen2(!chosen2);
                                    }} css={cssStyleBox1Choice2}>
                                        <div css={cssStyleBox2}>
                                            {choice2}
                                        </div>
                                        <svg viewBox="0 0 24 24" css={svgStyle2}>
                                            <path d="M7 10l5 5 5-5z"></path>
                                        </svg>
                                    </div>
                                </>)
                            }
                            {chosen2 && (
                                <ul css={dropDownList2}>
                                    <li onClick={() => {
                                        setChoice2('Replies');
                                        nullifyStates();
                                    }} css={css`${dropDownStyle};
                                        border-radius: 12px 12px 0px 0px;
                                        padding-top: 5px`}>Replies
                                    </li>
                                    <li onClick={() => {
                                        setChoice2('Quotes');
                                        nullifyStates();
                                    }} css={dropDownStyle}>Quotes
                                    </li>
                                    <li onClick={() => {
                                        setChoice2('Retweets');
                                        nullifyStates();
                                    }} css={css`${dropDownStyle};
                                        border-radius: 0px 0px 12px 12px;
                                        padding-bottom: 5px`}>Retweets
                                    </li>
                                </ul>
                            )}
                            {choice2 != 'Retweets' && (
                                <div>
                                    <div css={css`margin-top: 32px;`}>
                                        <div css={css`display: flex;
                                            flex-direction: row;
                                            align-items: center;`}>
                                            <SvgIcon component={FavoriteIcon}
                                                     style={{color: 'black', width: '1em', height: '1em',}}/>
                                            <div css={rangeTitleStyle}>Likes
                                            </div>
                                        </div>
                                        <div css={css`display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            /*width: 250px;*/`}>
                                            <div css={rangeValueStartStyle}>0</div>
                                            <Slider key={sliderKey} defaultValue={0} min={0} max={150} step={10}
                                                    marks={true} style={{color: 'black', width: '100%'}}
                                                    valueLabelDisplay="auto"
                                                    onChange={(event, newValue) => {
                                                        setLikes(newValue as number);
                                                    }}/>
                                            <div css={rangeValueStyle}>150+</div>
                                        </div>
                                    </div>
                                    <div css={css`margin-top: 32px;`}>
                                        <div css={css`display: flex;
                                            flex-direction: row;
                                            align-items: center;`}>
                                            <SvgIcon component={ReplyAllIcon}
                                                     style={{color: 'black', width: '1em', height: '1em',}}/>
                                            <div css={rangeTitleStyle}>Retweets
                                            </div>
                                        </div>
                                        <div css={css`display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            /*width: 250px;*/`}>
                                            <div css={rangeValueStartStyle}>0</div>
                                            <Slider key={sliderKey} defaultValue={0} min={0} max={150} step={10}
                                                    marks={true} style={{color: 'black', width: '100%'}}
                                                    valueLabelDisplay="auto"
                                                    onChange={(event, newValue) => {
                                                        setRetweets(newValue as number);
                                                    }}/>
                                            <div css={rangeValueStyle}>150+</div>
                                        </div>
                                    </div>
                                    <div css={css`margin-top: 32px;`}>
                                        <div css={css`display: flex;
                                            flex-direction: row;
                                            align-items: center;`}>
                                            <SvgIcon component={BookmarkIcon}
                                                     style={{color: 'black', width: '1em', height: '1em',}}/>
                                            <div css={rangeTitleStyle}>Bookmarks
                                            </div>
                                        </div>
                                        <div css={css`display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            /*width: 250px;*/`}>
                                            <div css={rangeValueStartStyle}>0</div>
                                            <Slider key={sliderKey} defaultValue={0} min={0} max={150} step={10}
                                                    marks={true}
                                                    style={{color: 'black', width: '100%'}} valueLabelDisplay="auto"
                                                    onChange={(event, newValue) => {
                                                        setBookmarks(newValue as number);
                                                    }}/>
                                            <div css={rangeValueStyle}>150+</div>
                                        </div>
                                    </div>
                                    <div css={css`margin-top: 32px;`}>
                                        <div css={css`display: flex;
                                            flex-direction: row;
                                            align-items: center;`}>
                                            <SvgIcon component={ReplyIcon}
                                                     style={{color: 'black', width: '1em', height: '1em',}}/>
                                            <div css={rangeTitleStyle}>Replies
                                            </div>
                                        </div>
                                        <div css={css`display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            /*width: 250px*/;`}>
                                            <div css={rangeValueStartStyle}>0</div>
                                            <Slider key={sliderKey} defaultValue={0} min={0} max={150} step={10}
                                                    marks={true}
                                                    style={{color: 'black', width: '100%'}} valueLabelDisplay="auto"
                                                    onChange={(event, newValue) => {
                                                        setReplies(newValue as number);
                                                    }}/>
                                            <div css={rangeValueStyle}>150+</div>
                                        </div>
                                    </div>
                                    <div css={css`margin-top: 32px;`}>
                                        <div css={css`display: flex;
                                            flex-direction: row;
                                            align-items: center;`}>
                                            <SvgIcon component={SpeakerNotesIcon}
                                                     style={{color: 'black', width: '1em', height: '1em',}}/>
                                            <div css={rangeTitleStyle}>Quotes
                                            </div>
                                        </div>
                                        <div css={css`display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            /*width: 250px*/;`}>
                                            <div css={rangeValueStartStyle}>0</div>
                                            <Slider key={sliderKey} defaultValue={0} min={0} max={150} step={10}
                                                    marks={true}
                                                    style={{color: 'black', width: '100%'}} valueLabelDisplay="auto"
                                                    onChange={(event, newValue) => {
                                                        setQuotes(newValue as number);
                                                    }}/>
                                            <div css={rangeValueStyle}>150+</div>
                                        </div>
                                    </div>
                                    <div css={css`margin-top: 32px;`}>
                                        <div css={css`display: flex;
                                            flex-direction: row;
                                            align-items: center;`}>
                                            <SvgIcon component={AlignVerticalBottomIcon}
                                                     style={{color: 'black', width: '1em', height: '1em',}}/>
                                            <div css={rangeTitleStyle}>Views
                                            </div>
                                        </div>
                                        <div css={css`display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            /*width: 250px*/;`}>
                                            <div css={rangeValueStartStyle}>0</div>
                                            <Slider key={sliderKey} defaultValue={0} min={0} max={50000} step={5000}
                                                    marks={true}
                                                    style={{color: 'black', width: '100%'}} valueLabelDisplay="auto"
                                                    onChange={(event, newValue) => {
                                                        setImpressions(newValue as number);
                                                    }}/>
                                            <div css={rangeValueStyle}>50k+</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {choice2 == 'Retweets' && (
                                <div css={css`margin-top: 32px;`}>
                                    <div css={css`display: flex;
                                        flex-direction: row;
                                        align-items: center;`}>
                                        <SvgIcon component={ReplyAllIcon}
                                                 style={{color: 'black', width: '1em', height: '1em',}}/>
                                        <div css={css`font-size: 1rem;
                                            margin-left: 16px;`}>Number of retweets
                                        </div>
                                    </div>
                                    <div css={css`display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        /*width: 250px*/;`}>
                                        <div css={rangeValueStartStyle}>0</div>
                                        <Slider key={sliderKey} defaultValue={0} min={0} max={21} step={3} marks={true}
                                                style={{color: 'black', width: '100%'}} valueLabelDisplay="auto"
                                                onChange={(event, newValue) => {
                                                    setNumberOfRetweets(newValue as number);
                                                }}/>
                                        <div css={rangeValueStyle}>21+</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <hr css={css`
                        flex-shrink: 0;
                        margin-left: 16px;
                        margin-right: 16px;
                        border-width: 0 thin 0 0;
                        border-style: solid;
                        border-color: black;
                        height: auto;
                        align-self: stretch;
                    `}/>

                    <div css={css`display: flex;
                        flex-direction: column;
                        align-items: center;
                    `}>
                        <TotalMetrics views={impressions} likes={likes} retweets={retweets} quotes={quotes}
                                      replies={replies} bookmarks={bookmarks} multiplier={multiplier}
                                      isRetweet={choice2 == 'Retweets'} ownRetweet={numberOfRetweets}
                        />
                        <hr css={css`${hrStyle};
                            border-color: black;
                            margin-top: 0;
                            
                            @media (min-width: 768px) {
                                margin-left: 15px;
                            }
                        `}/>

                        <div css={totalPointsStyles}>
                            <div css={css`align-self: center; white-space: nowrap;`}>
                                Total points:
                            </div>
                            <div css={totalPointsValueStyles}
                            >
                                {formatNumber(totalPoints)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}