/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react';
import {css} from '@emotion/react';
import {Box, Typography} from '@mui/material';
import {styled} from '@mui/system';
import {useUserProfile} from '../contexts/UserContext';
import axios from "axios";
import {text2XL, text3XL} from "../styles/OtherStyles";

const Container = styled(Box)({
    backgroundColor: 'white',
    border: '1px solid rgb(79, 79, 79)',
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: 'white',
    '@media (max-width: 380px)': {
        padding: '5px', // New padding for small screens
    },
});

const MetricContainer = styled(Box)({
    textAlign: 'center',
    padding: '10px 20px',
    borderRight: '1px solid rgb(79, 79, 79)',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&:last-child': {
        borderRight: 'none',
        borderBottom: 'none'
    },
    '@media (max-width: 380px)': {
        padding: '10px 5px',
    },
});

const MetricLabel = styled(Typography)({
    fontSize: '1rem',
    color: 'rgba(0, 0, 0,0.75)',
    marginBottom: '10px',
    '@media (max-width: 480px)': {
        fontSize: '4vw'
    }
});

const MetricValue = styled(Typography)({
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: 'rgba(0,0,0,1)',
    '@media (max-width: 480px)': {
        fontSize: '5vw'
    }
});

const CountdownContainer = styled(Box)({
    textAlign: 'center',
    color: 'black',
    marginBottom: '10px'
});

const StatsTitle: React.FC = () => {
    const {profile} = useUserProfile();
    const [countdown, setCountdown] = useState('');
    const formatNumber = (num: number) => {
        return new Intl.NumberFormat('en-US').format(num);
    };

    useEffect(() => {
        const fetchCooldown = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/cooldown`);
                const nextResetTime = new Date(response.data.nextResetTime);
                const interval = setInterval(() => {
                    const now = new Date().getTime();
                    const distance = nextResetTime.getTime() - now;

                    if (distance < 0) {
                        clearInterval(interval);
                        setCountdown('Resetting...');
                        return;
                    }

                    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                    setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
                }, 1000);

                return () => clearInterval(interval);
            } catch (error) {
                console.error('Error fetching cooldown:', error);
            }
        };

        fetchCooldown();
    }, []);

    const currentScore = profile ? formatNumber(profile.currentScore) : '-';
    const currentRanking = profile ? profile.currentRanking : '-';
    const lastWeekScore = profile ? formatNumber(profile.lastWeekScore) : '-';

    return (
        <>
            <CountdownContainer>
                <h2 css={css`
                    ${text2XL};
                    margin-top: 0;
                    @media (min-width: 1024px) {
                        ${text3XL};
                    }`}>Weekly reset in: {countdown}</h2>
            </CountdownContainer>
            <Container>
                <MetricContainer css={css`
                    p {
                        font-family: Montserrat;
                        font-weight: 600;
                    }
                `}>
                    <MetricLabel>MY TOTAL POINTS</MetricLabel>
                    <MetricValue>{currentScore}</MetricValue>
                </MetricContainer>
                <MetricContainer css={css`
                    p {
                        font-family: Montserrat;
                        font-weight: 600;
                    }
                `}>
                    <MetricLabel>MY POSITION</MetricLabel>
                    <MetricValue>{currentRanking}</MetricValue>
                </MetricContainer>
                <MetricContainer css={css`
                    p {
                        font-family: Montserrat;
                        font-weight: 600;
                    }
                `}>
                    <MetricLabel>THIS WEEK POINTS</MetricLabel>
                    <MetricValue>{lastWeekScore}</MetricValue>
                </MetricContainer>
            </Container>
        </>
    );
};

export default StatsTitle;
