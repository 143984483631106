/** @jsxImportSource @emotion/react */
import React, {FC, useEffect, useState} from 'react';
import { css } from '@emotion/react';
import {Link} from 'react-router-dom';
import {loginButtonStyle, statsButtonStyle} from '../styles/ButtonStyles';
import {useLocation} from "react-router";
import Popup from "./PopUp";
import {useUserProfile} from "../contexts/UserContext";
import ProfileName from "./ProfileName";
import Logo from "./Logo";
import {textSM, textXS} from "../styles/OtherStyles";

const headerStyle = css`
  background-color: rgb(18, 18, 18);
  color: rgb(255, 255, 255);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(255, 255, 255, 0.12);
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  position: fixed;
  z-index: 1100;
  top: 0px;
  left: auto;
  right: 0px;
`;

const headerDivStyle = css`
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-height: 56px;
    margin: 0 12px;
    
    @media (min-width: 768px) {
        margin: 0 16px
    }

    @media (min-width: 600px) {
        min-height: 64px;
    }
    @media (min-width: 600px) {
      padding-left: 24px;
      padding-right: 24px;
    }
    `;

const logoStyle = css`
  margin: 0;
  font-family: Montserrat;
  line-height: 1.167;
  font-size: 22px;
  white-space: nowrap;
  font-weight: 600;
`;



const Navbar: FC = () => {

    const { isPopupOpen, setPopupOpen } = useUserProfile();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('popup') === 'true') {
            setPopupOpen(true);
        } else {
            setPopupOpen(false);
        }
    }, [location.search, setPopupOpen]);

    const handleLoginClick = () => {
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
    };

    const { profile } = useUserProfile();


    return (
        <header css={headerStyle}>
            <div css={headerDivStyle}>
                <div css={css`display: block;cursor: pointer`}>
                    <Logo />
                </div>
                <Link css={css`display: flex;
                    flex-direction: row;
                    text-decoration: none;
                    flex-grow: 1;`} to='/stats'>
                    <div css={css`
                        margin: 0 4px 0 10px;
                        overflow: auto;
                        @media (min-width: 768px) {
                            margin: 0 16px;
                        }
                    `}>
                        <button css={css`
                            ${logoStyle};
                            ${textXS};
                            cursor: pointer;
                            text-decoration: none;
                            color: white;
                            border: 1px solid white;
                            background-color: inherit;
                            padding: 4px 16px;
                            transition: 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                            text-transform: uppercase;
                            font-weight: 700;

                            &:hover {
                                background-color: white;
                                color: black;
                                border-color: rgba(0, 0, 0, .2);
                            }
                            
                            @media (min-width: 768px) {
                                ${textSM};
                            }
                        `}>
                            stats
                        </button>
                    </div>
                </Link>
                <ProfileName/>
                {!profile ?
                    (<button css={css`
                        ${loginButtonStyle};
                    `} onClick={handleLoginClick}>
                        login to start
                    </button>) :
                    (<button css={css`
                        ${loginButtonStyle};
                    `} onClick={handleLoginClick}>
                        calculate points
                    </button>)
                }
            </div>
            {isPopupOpen && (
                <Popup onClose={handleClosePopup}/>
            )}
        </header>
    );
};

export default Navbar;
