import React from "react";
import { createBrowserRouter,RouteObject } from "react-router-dom"
import Stats from "../pages/Stats";
import Main from "../pages/Main";
import Legal from "../pages/Legal";
import Privacy from "../pages/Privacy";

const routes: RouteObject[] = [
    {
        path: "/",
        element: <Main/>,
    },
    {
        path: "/stats",
        element: <Stats />
    },
    {
        path: "/legal",
        element: <Legal />
    },
    {
        path: "/privacy",
        element: <Privacy/>
    }
];

const router = createBrowserRouter(routes);

export default router;
