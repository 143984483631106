/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import LinkIcon from '@mui/icons-material/Link';
import logo from "../assets/logo.png";
import LanguageIcon from '@mui/icons-material/Language';
import XIcon from '@mui/icons-material/X';
import DiscordIcon from "./DiscordIcon";
import logoAD from "../assets/ad.svg";
import logoYO from "../assets/yo.svg";


const Footer = () => {

    const footerStyles = css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 36px;
        margin-top: 32px;
        margin-bottom: 24px;
        
        @media (min-width: 768px) {
            margin-top: 64px;
            margin-bottom: 44px;
        }
    `;

    const topRowStyles = css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        flex-direction: column;
        row-gap: 12px;

        @media (min-width: 360px) {
            flex-direction: row;
        }


        @media (min-width: 768px) {
            column-gap: 32px;
        }
        @media (min-width: 1024px) {
            column-gap: 64px;
        }

        @media (min-width: 1280px) {

        }
    `;

    const navWrapperStyles = css`
        display: flex;
    `;

    const logoStyles = css`
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        & > img {
            &:first-child {
                margin-top: -4px;
            }

            height: 18px;
        }

        & > div {
            width: 100%;
            margin: 0 4px;
            height: 4px;
            background: black;
        }

    `;

    return (
        <footer css={footerStyles}>

            <div css={topRowStyles}>

                {/*Article/Docs*/}
                <div css={navWrapperStyles}>
                    <div css={css`
                        background-color: black;
                        display: inline-flex;
                        align-items: center;
                        color: white;
                        border-radius: 5px;
                        padding: 4px 10px;

                    `}>
                        <a href="https://medium.com/@alice_61545/post-to-earn-airdrop-campaign-7e2bf2ec4a0a"
                           target="_blank"
                           rel="noopener noreferrer"
                           css={css`
                               display: flex;
                               align-items: center;
                               margin-right: 20px; /* Space between items */
                               color: white; /* Ensure the link text is white */
                               text-decoration: none; /* Remove underline */
                           `}>
                            Article
                            <LinkIcon style={{color: 'white', transform: 'rotate(305deg)', marginLeft: '8px'}}/>
                        </a>
                        <a href="https://www.ad-yo.com/technology#protocol"
                           target="_blank"
                           rel="noopener noreferrer"
                           css={css`
                               display: flex;
                               align-items: center;
                               color: white; /* Ensure the link text is white */
                               text-decoration: none; /* Remove underline */
                           `}>
                            Docs
                            <LinkIcon style={{color: 'white', transform: 'rotate(305deg)', marginLeft: '8px'}}/>
                        </a>
                    </div>
                </div>

                {/*Social links*/}

                <div css={navWrapperStyles}>
                    <div css={css`
                        background-color: black;
                        display: inline-flex;
                        align-items: center;
                        color: white;
                        border-radius: 5px;
                        padding: 4px 10px;
                    `}>
                        <a href="https://www.ad-yo.com/"
                           target="_blank"
                           rel="noopener noreferrer"
                           css={css`
                               display: flex;
                               align-items: center;
                               color: white;
                               text-decoration: none;
                               margin-right: 10px;
                           `}>
                            <LanguageIcon style={{color: 'white', marginRight: '5px'}}/>
                        </a>
                        <a href="https://x.com/AdYoAd"
                           target="_blank"
                           rel="noopener noreferrer"
                           css={css`
                               display: flex;
                               align-items: center;
                               color: white;
                               text-decoration: none;
                               margin-right: 10px;
                           `}>
                            <XIcon style={{color: 'white', marginRight: '5px'}}/>
                        </a>
                        <a href="/"
                           target="_blank"
                           rel="noopener noreferrer"
                           css={css`
                               display: flex;
                               align-items: center;
                               color: white;
                               text-decoration: none;
                           `}>
                            <DiscordIcon style={{color: 'white'}}/>
                        </a>
                    </div>
                </div>
            </div>

            {/*Bottom Nav*/}

            <div css={css`
                display: flex;
                align-self: center;
                justify-content: space-around;
                width: 100%;
                @media (min-width: 768px) {
                    width: 55%;
                }
            `}>
                <a href="/legal"
                   css={css`
                       color: black;
                       text-decoration: underline;

                       &:visited {
                           color: black;
                       }
                   `}>
                    Terms of Conditions
                </a>
                <a href="/privacy"
                   css={css`
                       color: black;
                       text-decoration: underline;

                       &:visited {
                           color: black;
                       }
                   `}>
                    Privacy Policy
                </a>
            </div>

            {/*Logo*/}

            <div css={logoStyles}>
                <img src={logoAD} alt="logo"/>
                <div></div>
                <img src={logoYO} alt="logo"/>
            </div>
        </footer>
    )
}

export default Footer;