/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {textSM, textXS} from "./OtherStyles";


export const statsButtonStyle = css`
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Montserrat;
    font-weight: 500;
    text-transform: uppercase;
    min-width: 64px;
    padding: 4px 5px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: white;
    &:hover {
      color: black;
    }
`;

export const loginButtonStyle = css`
    display: inline-flex;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Montserrat;
    font-weight: 600;
    text-transform: uppercase;
    min-width: 64px;
    padding: 2px 16px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: black;
    background-color: white;
    white-space: nowrap;
    border: 1px solid black;
    ${textXS};

    position: relative;


    &::before {
        content: '';
        position: absolute;
        pointer-events: none;
    }

    &::before {
        border: 1px solid white;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
    }
    
    @media (min-width: 768px) {
        ${textSM};
    }

    &:hover {
        background-color: black;
        color: white;
        //border-color: white;
    }
`;