/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {text2XL, textBase, textLG, textXL} from "../styles/OtherStyles";

const listStyles = css`
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    row-gap: 16px;
    
    @media (min-width: 768px) {
        row-gap: 30px;
    }
`;

const listItemStyles = css`
    display: flex;
    align-items: start;

`;

const listIconStyle = css`
    min-width: 56px;
    color: black;
    flex-shrink: 0;
    display: inline-flex;
    margin-top: 4px;
`;

const listContentStyle = css`
    flex: 1 1 auto;
    min-width: 0;
`;

const listContentSpanStyle = css`
    margin: 0 0 4px;
    font-weight: 600;
    ${textXL};
    @media (min-width: 1024px) {
        ${text2XL};
    }
    display: block;
`;

const listContentPStyle = css`
    margin: 0;
    font-weight: 500;
    display: block;
    
    ${textBase};

    @media (min-width: 768px) {
        ${textLG};
    }
`;


const svgIconStyle = css`
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    font-size: 2rem;`

export const GetStartedList = () => {

    return (
        <ul css={listStyles}>
            <li css={listItemStyles}>
                <div css={listIconStyle}>
                    <svg css={svgIconStyle} viewBox="0 0 24 24">
                        <path
                            d="M3 5H1v16c0 1.1.9 2 2 2h16v-2H3V5zm11 10h2V5h-4v2h2v8zm7-14H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 16H7V3h14v14z"></path>
                    </svg>
                </div>
                <div css={listContentStyle}>
                    <span css={listContentSpanStyle}>Hashtags</span>
                    <p css={listContentPStyle}>All posts, quotes, and replies must contain #ADYO or $ADYO tags to earn
                        points</p>
                </div>
            </li>
            <li css={listItemStyles}>
                <div css={listIconStyle}>
                    <svg css={svgIconStyle} viewBox="0 0 24 24">
                        <path d="M3 5H1v16c0 1.1.9 2 2 2h16v-2H3V5zm18-4H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2
                        2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 16H7V3h14v14zm-4-4h-4v-2h2c1.1 0
                        2-.89 2-2V7c0-1.11-.9-2-2-2h-4v2h4v2h-2c-1.1 0-2 .89-2 2v4h6v-2z"></path>
                    </svg>
                </div>
                <div css={listContentStyle} id='explain'>
                    <span css={listContentSpanStyle}>Original posts</span>
                    <p css={listContentPStyle}>Must have at least 10 words</p>
                </div>
            </li>
            <li css={listItemStyles}>
                <div css={listIconStyle}>
                    <svg css={svgIconStyle} viewBox="0 0 24 24">
                        <path d="M21 1H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2
                        2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 16H7V3h14v14zM3
                        5H1v16c0 1.1.9 2 2 2h16v-2H3V5zm14 8v-1.5c0-.83-.67-1.5-1.5-1.5.83 0
                        1.5-.67 1.5-1.5V7c0-1.11-.9-2-2-2h-4v2h4v2h-2v2h2v2h-4v2h4c1.1 0 2-.89 2-2z"></path>
                    </svg>
                </div>
                <div css={listContentStyle}>
                    <span css={listContentSpanStyle}>Quote AdYo related posts</span>
                    <p css={listContentPStyle}>Must have at least 10 words</p>
                </div>
            </li>
            <li css={listItemStyles}>
                <div css={listIconStyle}>
                    <svg css={svgIconStyle} viewBox="0 0 24 24">
                        <path
                            d="M3 5H1v16c0 1.1.9 2 2 2h16v-2H3V5zm12 10h2V5h-2v4h-2V5h-2v6h4v4zm6-14H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 16H7V3h14v14z"></path>
                    </svg>
                </div>
                <div css={listContentStyle}>
                    <span css={listContentSpanStyle}>Replies on AdYo related posts</span>
                    <p css={listContentPStyle}>At least 3 words excluding hashtags and mentions</p>
                </div>
            </li>
        </ul>
    )
}