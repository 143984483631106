import React, {useEffect, useRef, useState} from 'react';

import {
    CanvasAnimatorAnimation,
    EASE,
    IAnimationProps,
    IEasingFunction,
    Square
} from "../shared/lib/CanvasAnimator";
import {AdYoCanvasAnimator} from "../shared/lib";

const CanvasComponent: React.FC = () => {
    const refCanvas = useRef<HTMLCanvasElement>(null);
    const canvasWidthMobile = 80;
    const canvasWidthDesktop = 150;
    let canvasCenterX = canvasWidthDesktop / 2;
    let lastY = 0;
    const pt = 4;
    const sizesWeights = [50, 25, 10, 7, 3];
    const sizes = [pt, pt * 2, pt * 5, pt * 12, pt * 18];
    const minWidth = pt * 3;
    let animator: AdYoCanvasAnimator | undefined;
    let observer: IntersectionObserver;

    const weighedRandom = (weights: number[]): number => {
        const sum = weights.reduce((acc, weight) => acc + weight, 0);
        const rand = Math.random() * sum;
        let acc = 0;

        for (let i = 0; i < weights.length; i++) {
            acc += weights[i];
            if (rand < acc) {
                return i;
            }
        }

        return 0;
    };

    const fillCanvas = () => {
        const canvas = refCanvas.current;
        if (canvas) {
            while (lastY < canvas.height) {
                const xStart = Math.random() > 0.5 ? canvasCenterX : Math.random() * canvasCenterX;
                const widthStart = minWidth + (Math.random() * ((Math.round(canvasCenterX)) - minWidth));
                const h = sizes[weighedRandom(sizesWeights)];
                const widthEnd = Math.random() * canvasCenterX;
                const xEnd = xStart === canvasCenterX ? 0 : canvasCenterX;
                const margin = Math.random() > 0.5 ? 0 : pt;
                const duration = 2000 + Math.random() * 3000;
                const delay = Math.random() * 1000;

                addLoopAnimation(
                    {x: xStart, y: lastY, width: widthStart, height: h},
                    {x: xEnd, width: widthEnd},
                    duration,
                    delay,
                    EASE.IN_OUT_CUBIC
                );

                lastY += h + margin;
            }
        }
    };

    const addLoopAnimation = (
        fromProps: IAnimationProps,
        toProps: IAnimationProps,
        duration: number,
        delay: number,
        ease: IEasingFunction,
        startsFromPercentage = 0
    ) => {
        const square = new Square(fromProps.x, fromProps.y, fromProps.width, fromProps.height, 'black');
        let currentProps = toProps;
        let newFromProps = {} as IAnimationProps;

        for (const propKey in toProps) {
            const key = propKey as keyof IAnimationProps;
            newFromProps[key] = fromProps[key];
        }

        fromProps = newFromProps;

        const animation = new CanvasAnimatorAnimation(
            square,
            currentProps,
            duration,
            0,
            ease,
            () => {
                currentProps = currentProps === toProps ? fromProps : toProps;
                animation.setTarget(currentProps, delay);
            }
        );

        animator?.addAnimation(animation);
    };

    const addAnimations = () => {
        if (!animator) return;
        const animations = animator.getAnimations();

        const lastAnimation = animations.reduce((max, current) => {
            return max.displayObject.bottom > current.displayObject.bottom ? max : current;
        });

        if (lastAnimation.displayObject.y < window.innerWidth) {
            lastY = lastAnimation.displayObject.bottom;
            fillCanvas();
        }
    };

    const removeAnimations = () => {
        const animations = animator?.getAnimations();
        animations?.forEach((animation) => {
            if (animation.displayObject.y > window.innerWidth) {
                animator?.removeAnimation(animation);
            }
        });
    };

    const redraw = (newWidth: number) => {
        const canvas = refCanvas.current;
        if (canvas) {
            canvas.width = newWidth;
            canvasCenterX = newWidth / 2;
            lastY = 0;

            if(animator) {
                animator.removeAllAnimations();
                animator.setCanvasWidth(newWidth);
                fillCanvas();
            }
        }
    };

    const onResize = () => {
        const canvas = refCanvas.current;
        if (canvas) {
            const mob = 767;
            if (window.innerWidth <= mob && canvas.width !== canvasWidthMobile) {
                redraw(canvasWidthMobile);
            } else if (window.innerWidth > mob && canvas.width !== canvasWidthDesktop) {
                redraw(canvasWidthDesktop);
            }

            if (window.innerWidth > canvas.height) {
                canvas.height = window.innerWidth;
                addAnimations();
            } else if (window.innerWidth < canvas.height) {
                canvas.height = window.innerWidth;
                removeAnimations();
            }
        }
    };


    useEffect(() => {
        const canvas = refCanvas.current;
        if (canvas) {
            window.addEventListener('resize', onResize);
            onResize();
            animator = new AdYoCanvasAnimator(canvas, canvas.width, pt);
            fillCanvas();

            observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        animator?.isStarted() ? animator.resume() : animator?.start();
                    } else {
                        animator?.pause();
                    }
                });
            });

            observer.observe(canvas);
        }

        return () => {
            animator?.stop();
            lastY = 0;
            window.removeEventListener('resize', onResize);
            observer?.disconnect();
        };
    }, []);

    return (
        <div className="abstraction-wrapper">
            <canvas ref={refCanvas} width={canvasWidthDesktop} className="abstraction-canvas horizontal"/>
        </div>
    );
};

export default CanvasComponent;