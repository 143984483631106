/** @jsxImportSource @emotion/react */
import {FC} from 'react';
import {css} from '@emotion/react';
import {loginButtonStyle} from "../styles/ButtonStyles";
import {
    blockTitleStyles, containerMobFullStyles, containerStyles,
    mainContentStyles, pageTitleStyles,
    panelWrapperStyles,
    textBase,
    textLG,
} from "../styles/OtherStyles";
import {TwitterSpan} from "./TwitterSpan";
import {MultipliersList} from "./MultipliersList";
import {ProcessList} from "./ProcessList";
import {GetStartedList} from "./GetStartedList";
import {InteractiveCalculator} from "./InteractiveCalculator";
import adLogo from "../assets/ad.svg";
import yoLogo from "../assets/yo.svg";
import Footer from "./Footer";
import {useUserProfile} from "../contexts/UserContext";
import Disclamer from "./Disclamer";
import {Link} from "react-router-dom";
import Abstraction from "./Abstraction";


const handleProcessScroll = () => {
    const targetSection = document.getElementById('process');
    if (targetSection) {
        targetSection.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
};

const handleExplainScroll = () => {
    const targetSection = document.getElementById('explain');
    if (targetSection) {
        targetSection.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
}

const handleDisclaimerScroll = () => {
    const targetSection = document.getElementById('disclaimer');
    if (targetSection) {
        targetSection.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
}


const styles = {
    logo: css`
        @media (min-width: 768px) {

        }
        @media (min-width: 1024px) {

        }

        @media (min-width: 1280px) {

        }
    `,



    airdropWrapper: css`
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 48px;
    `,

    logoPart: css`
        display: flex;
        flex-grow: 1;
        align-items: center;

        img {
            height: 16px;
        }

        @media (min-width: 768px) {
            img {
                height: 40px;
            }
        }
        @media (min-width: 1024px) {
            img {
                height: 80px;
            }
        }
    `,

    logoPartAd: css`
        img {
            margin-top: -5px;

            @media (min-width: 768px) {
                margin-top: -13px;
            }

            @media (min-width: 1024px) {
                margin-top: -20px;
            }
        }
    `,

    logoLine: css`
        width: 100%;

        background-color: black;
        height: 3px;
        margin: 0 4px;

        @media (min-width: 768px) {
            height: 8px;
            margin: 0 8px;
        }

        @media (min-width: 1024px) {
            height: 12px;
            margin: 0 12px;
        }
    `,

    logoText: css`

        font-weight: 700;
        font-size: 1.25rem;
        margin: 0 0;

        @media (min-width: 768px) {
            font-size: 2.25rem;
            margin: 0 18px;
        }
        @media (min-width: 1024px) {
            font-size: 3rem;
            margin: 0 24px;
        }
    `,

    panelsWrapper: css`
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        @media (min-width: 768px) {
            row-gap: 24px;
            margin-top: 44px;
        }

        @media (min-width: 1024px) {
            row-gap: 44px;
            margin-top: 64px;
        }

        @media (min-width: 1280px) {
            row-gap: 64px;
            margin-top: 100px;
        }
    `,

    panelsRowWrapper: css`
        display: flex;

        flex-direction: column;

        & > * {
            flex: 1 1 0;
        }

        @media (min-width: 768px) {
            gap: 16px;

        }

        @media (min-width: 1024px) {
            flex-direction: row;
            gap: 32px;
        }
    `,

    paragraphStyles: css`
        font-weight: 500;
        ${textBase};
        
        @media (min-width: 768px) {
            ${textLG};
        }

        p {
            margin: 20px 0;
        }
    `,

    text: css`
        font-size: 2.3rem;
        white-space: nowrap;
        font-family: Montserrat;
        font-weight: 700;
        width: 25%;
        margin-left: 250px;
        margin-right: 250px;

        @media (max-width: 1050px) {
            margin-left: 0;
            margin-right: 0;
        }
    `,
};

const MainContent: FC = () => {

    const {setPopupOpen} = useUserProfile();

    return (
        <div css={mainContentStyles}>
            <div css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
            `}>
                <div css={css`${containerStyles};
                    width: 100%;
                    margin-top: 44px;
                `}>

                    <h1 css={pageTitleStyles}>Post-to-Earn</h1>
                    <div css={styles.airdropWrapper}>
                        <div css={css`${styles.logoPart} ${styles.logoPartAd}`}>
                            <img src={adLogo} alt="Logo1"/>
                            <div css={styles.logoLine}></div>
                        </div>

                        <h2 css={styles.logoText}>Airdrop Campaign</h2>

                        <div css={styles.logoPart}>
                            <div css={styles.logoLine}></div>
                            <img src={yoLogo} alt="Logo2"/>
                        </div>
                    </div>
                </div>

                <div css={css`width: 100%;`}>
                    <Abstraction/>
                </div>
                <div css={css`
                    ${containerStyles};
                    display: flex;
                    flex-direction: column;
                    align-items: center;`}>
                    <div css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-top: 36px;
                        row-gap: 16px;
                        
                        @media (min-width: 768px) {
                            margin-top: 64px;
                            flex-direction: row;
                            column-gap: 16px;
                            align-items: center;
                        }
                    `}>
                        <button css={css`${loginButtonStyle};
                            font-size: 15px;
                            padding: 10px 20px;
                        `} onClick={handleProcessScroll}>
                            Earn Points
                        </button>
                        <Link to='/stats'>
                            <button css={css`${loginButtonStyle};
                                color: white;
                                background-color: rgb(18, 18, 18);
                                border: 1px solid black;
                                font-size: 15px;
                                padding: 8px 20px;
                                width: calc(100% - 2px);
                                position: relative;
                                
                                
                                &::before {
                                    content: '';
                                    position: absolute;
                                    pointer-events: none;
                                }
                                
                                &::before {
                                    border: 1px solid black;
                                    top: -3px;
                                    left: -3px;
                                    right: -3px;
                                    bottom: -3px;
                                }
                                
                                &:hover {
                                    background-color: white;
                                    color: black;
                                    border-color: white;
                                }

                                @media (min-width: 768px) {
                                    width: auto;
                                }
                            `}>
                                Stats
                            </button>
                        </Link>
                        <button css={css`${loginButtonStyle};
                            font-size: 15px;
                            padding: 10px 20px;
                        `} onClick={handleExplainScroll}>
                            Learn more
                        </button>
                    </div>
                </div>

                {/*Body*/}

                <div css={css`${containerStyles} ${containerMobFullStyles}`}>
                    {/*Panels*/}
                    <div css={styles.panelsWrapper}>
                        {/*Panels top*/}
                        <div css={styles.panelsRowWrapper}>
                            {/*How it works block*/}
                            <div css={panelWrapperStyles}>
                                <ProcessList/>
                            </div>

                            {/*Expected point calculator block*/}
                            <div css={panelWrapperStyles}>
                                <InteractiveCalculator/>
                            </div>

                        </div>


                        {/*Panels bottom*/}
                        <div css={styles.panelsRowWrapper}>

                            {/*How points work block*/}
                            <div css={panelWrapperStyles}>
                                <h3 css={blockTitleStyles}>
                                    How points work
                                </h3>
                                <div css={styles.paragraphStyles}>
                                    <p>Use Twitter/X account to earn points!</p>

                                    <p>Reply, quote, or tweet out mentioning <TwitterSpan
                                        word="$ADYO"/> and/or <TwitterSpan
                                        word="#ADYO"/> tags and you will automatically be awarded points for each view,
                                        like, reply, repost and bookmark your content receives.
                                        Repost content of others mentioning ADYO tags and claim fixed points rewards.
                                    </p>

                                    <p>
                                        Depending on the type of interaction, your points will have a different
                                        multiplier:
                                    </p>
                                </div>
                                <MultipliersList/>
                                <div css={styles.paragraphStyles}>
                                    <p css={css`margin-bottom: 0 !important;`}>
                                        Think of content that has potential to go viral; the bigger the reach, the more
                                        tokens
                                        you can expect… </p>
                                </div>
                            </div>

                            {/*Rules for posts block*/}
                            <div css={css`${panelWrapperStyles}; display: flex; flex-direction: column; justify-content: space-between;`}>
                                <div>
                                    <h3 css={blockTitleStyles}>Rules for posts</h3>
                                    <GetStartedList/>
                                </div>
                                <button css={css`${loginButtonStyle};
                                    color: white;
                                    background-color: black;
                                    border: 1px solid white;
                                    font-size: 15px;
                                    padding: 10px 20px;
                                    width: 100%;
                                    margin-top: 60px;

                                    &:hover {
                                        background-color: white;
                                        border: 1px solid black;
                                        color: black;
                                    }`
                                } onClick={() => {
                                    setPopupOpen(true)
                                }}>Get Started
                                </button>
                            </div>
                        </div>
                    </div>

                    <div css={css`
                        ${containerStyles};
                        display: flex;
                        flex-direction: column;
                        align-items: center;`}>
                        <Disclamer/>
                        <Footer/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainContent;