import React from 'react';
import logo from './logo.svg';
import './styles/GlobalStyles';
import Main from "./pages/Main";
import GlobalStyles from './styles/GlobalStyles';
import { ThemeProvider } from '@mui/material/styles';
import {RouterProvider} from "react-router-dom";
import router from "./routes/Routes";
import CssBaseline from '@mui/material/CssBaseline';
import theme from './styles/theme';
import {UserProvider} from "./contexts/UserContext";



function App() {
  return (
      <>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
            <GlobalStyles />
            <RouterProvider router={router} />
        </ThemeProvider>
      </UserProvider>

      </>
  );
}

export default App;
