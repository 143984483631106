/** @jsxImportSource @emotion/react */
import {FC} from 'react';
import {Helmet} from 'react-helmet';
import Navbar from '../components/Navbar';
import {css} from '@emotion/react';
import {
    containerStyles,
    pageTitleStyles,
    text2XL,
    text3XL,
    textBase,
    textLG,
    textXL
} from "../styles/OtherStyles";
import Footer from "../components/Footer";

const Privacy: FC = () => {
    return (
        <article css={css`${containerStyles}; max-width: 1024px; margin: 0 auto;`}>
            <Helmet>
                <title>Privacy</title>
                <link rel="canonical" href="https://post2earn.co.uk/privacy"/>
            </Helmet>
            <Navbar/>
            <h1
                css={css`
                    ${pageTitleStyles};
                    text-transform: uppercase;
                    text-align: center;
                    margin-top: 120px;
                    margin-bottom: 32px;
                `}
            >
                Privacy
            </h1>
            <div css={css`
                padding-bottom: 40px;

                h2 {
                    ${text2XL};

                    margin: 28px 0;
                    @media (min-width: 1024px) {
                        ${text3XL};
                    }
                }

                h3 {
                    ${textLG};

                    @media (min-width: 1024px) {
                        ${textXL};
                    }
                    margin: 24px 0;
                }

                p, li {
                    ${textBase};
                    font-weight: 500;
                }
                
                li {
                    margin: 16px 0;
                }
            `}>
                <div>
                    <h2>
                        Privacy Policy
                    </h2>
                    <p>
                        AdYo is committed to protecting and respecting your privacy. This Privacy Policy outlines how we
                        collect, use, disclose, and protect your personal information when you visit our website or
                        engage with our services.
                    </p>
                    <h3>
                        Information We Collect
                    </h3>
                    <ol>
                        <li>
                            Information You Provide: When you interact with us, you may provide us with personal
                            information such as your name, email address, phone number, and any other information you
                            choose to share;
                        </li>
                        <li>
                            Automatically Collected Information: We may automatically collect certain information
                            about your visit to our website, including your IP address, browser type, operating system,
                            referral source, and other usage details;
                        </li>
                        <li>
                            Cookies: Our website uses cookies to enhance your experience. You can choose to accept or
                            decline cookies through your browser settings. Service or a third-party service.
                        </li>
                    </ol>
                    <h3>
                        How We Use Your Information
                    </h3>
                    <ol>
                        <li>
                            To provide and improve our services to you.
                        </li>
                        <li>
                            To communicate with you about our services, promotions, and updates.
                        </li>
                        <li>
                            To analyze trends, administer our website, and gather demographic information.
                        </li>
                        <li>
                            To comply with legal requirements and protect our rights.
                        </li>
                    </ol>
                    <h3>
                        Disclosure of Your Information
                    </h3>
                    <ol>
                        <li>
                            With service providers who assist us in operating our website and providing our services;
                        </li>
                        <li>
                            With our business partners for joint marketing efforts;
                        </li>
                        <li>
                            With law enforcement agencies or governmental bodies when required by law.
                        </li>
                    </ol>
                    <h3>
                        Data Security
                    </h3>
                    <p>
                        We take appropriate measures to protect your personal information from unauthorized access,
                        alteration, disclosure, or destruction.
                    </p>
                    <h3>
                        Your rights
                    </h3>
                    <p>
                        You have the right to:
                    </p>
                    <ol>
                        <li>
                            Access and update your personal information.
                        </li>
                        <li>
                            Request that we delete your personal information.
                        </li>
                        <li>
                            Opt-out of receiving marketing communications from us.
                        </li>
                    </ol>
                    <h3>
                        Changes to This Privacy Policy
                    </h3>
                    <p>
                        We may update this Privacy Policy from time to time. Any changes will be posted on this page
                        with a revised effective date.
                    </p>
                    <h3>
                        Contact Us
                    </h3>
                    <p>
                        If you have any questions or concerns about our Privacy Policy or our practices regarding your
                        personal information, please contact us at adyoteam@gmail.com. By using our website and
                        services, you consent to the terms of this Privacy Policy.
                    </p>
                </div>
            </div>
            <Footer/>
        </article>
    )
};

export default Privacy;